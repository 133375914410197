<template>
    <div class="index">
        <div class="ls_flex">
            <a
                v-for="item in menu"
                :key="item.name"
                :target="item.isOut ? '_blank' : ''"
                :href="item.route"
            >
                {{ item.name }}
            </a>
        </div>

        <!-- 功能导航盒子 -->
        <div class="ls_flex_between ls_aic mt14">
            <div
                v-if="!currentSchool.formal"
                class="ls_flex_around plr30 ls_aic wp40 h175 bgcf br4 box-bb ls_cp"
                @click="
                    openUrl(
                        'https://mp.weixin.qq.com/s?__biz=MzkxOTQzODU5Mw==&mid=2247483776&idx=1&sn=cd9507a753527be1f18a828a2d054b8f&chksm=c1a355eef6d4dcf8c2ec40f4d77fd7e523560ef5e71f375227ecd5e5379921776a2cc9bf315a#rd'
                    )
                "
            >
                <div class="ls_tac">
                    <img
                        class="mb13"
                        src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/xiaologo.png"
                    />
                    <div class="fz16 c0 lh24">绿数花旅</div>
                    <div class="fz16 c6 lh24">AI 真花虚拟仿真</div>
                </div>
                <img
                    class="w24 h24"
                    src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/chacha.png"
                />
                <div class="ls_tac">
                    <img
                        class="mb13"
                        src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/feiyihezuo.png"
                    />
                    <div class="fz16 c0 lh24">岁时清供</div>
                    <div class="fz16 c6 lh24">陈荣顺_非遗大师</div>
                </div>
            </div>
            <div
                class="wp26 h87 bgcf br4 ls_cp"
                :class="{ wp49: currentSchool.formal }"
            >
                <div
                    class="ls_flex_center h87"
                    @click="
                        openUrl(
                            'https://mp.weixin.qq.com/s?__biz=MzkxOTQzODU5Mw==&mid=2247483718&idx=1&sn=61fe4004cd6bac766d17a3213e549f68&chksm=c1a35528f6d4dc3eb10f163514a3bf2fecd66ee9c0f3ef576606ffc4bd7843c4e59ecb3682bc#rd'
                        )
                    "
                >
                    <img
                        class="mr25"
                        src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/jiaocheng1.png"
                    />
                    <span class="fz16 c0">使用教程</span>
                </div>
            </div>
            <div
                class="wp32 h87 bgcf br4 ls_cp"
                :class="{ wp50: currentSchool.formal }"
            >
                <div
                    class="ls_flex_center h87"
                    @click="
                        openUrl(
                            'https://mp.weixin.qq.com/s?__biz=MzkxOTQzODU5Mw==&mid=2247483766&idx=1&sn=b22701984bacef5a27c1fa50600b63d5&chksm=c1a35518f6d4dc0ed8211759d719d72926083395cce3d95e2a21a4a49d5d770c733be0cacc64#rd'
                        )
                    "
                >
                    <img
                        class="mr26"
                        src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/jiazhidu.png"
                    />
                    <span class="fz16 c0">软件介绍</span>
                </div>
            </div>
        </div>

        <!-- 用户作品推荐 列表 -->
        <div class="work-box bgcf mt14 p24 ls_p_rel br4">
            <!-- 浮动刷新按钮 -->
            <div class="ls_p_abs r94 t43 ls_cp" @click="nextPage">
                <i class="el-icon-refresh mr10"></i>
                <span class="ls_link">换一批</span>
            </div>

            <!-- 主标题 -->
            <div class="ls_flex_center ls_aic">
                <div
                    class="plr6 fz18 lh26 c0"
                    v-for="(item, index) in '用户作品推荐'"
                    :key="index"
                >
                    {{ item }}
                </div>
            </div>
            <!-- 标题提示 -->
            <div class="ls_flex_center ls_aic">
                <div
                    class="plr6 fz10 lh26 c9"
                    v-for="(item, index) in 'Recommended Works'"
                    :key="index"
                >
                    {{ item }}
                </div>
            </div>

            <!-- 列表 -->
            <div class="mt30 ls_flex ls_flex_wrap">
                <div
                    class="wp19 p12 b-solid br8 box-bb ls_p_rel ls_ovh ls_flex_sk mb14 hover-item ls_cp"
                    @click="toEditDetail(item)"
                    v-for="(item, index) in workList"
                    :key="index"
                >
                    <img
                        class="wp100 mb10"
                        :src="item.coverImage | imgFormat"
                    />
                    <div class="fz14 lh30 c0">{{ item.name }}</div>
                    <div class="ls_flex_between ls_aic">
                        <div class="fz12 c6">{{ item.userName }}</div>

                        <div class="ls_flex ls_aic">
                            <img
                                class="mr10"
                                src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/shoucang2.png"
                            />
                            <div class="fz12 c6">{{ item.likeCount }}</div>
                        </div>
                    </div>
                    <div class="fz12 mt6 c9">{{ item.institutionName }}</div>

                    <!-- 浮动悬浮按钮区域 -->
                    <div
                        class="ls_p_abs l0 t0 h38 wp100 ls_flex_around ls_aic box-bb bgc08 hover-box"
                    >
                        <div
                            class="cf ls_cp pl30 ls_flex_center"
                            @click.stop="share(item)"
                        >
                            <i class="iconfont icon-fenxiang mr6"></i>
                            <span class="fz14">分享</span>
                        </div>
                        <div class="bgcf w1 h20"></div>
                        <div
                            class="cf ls_cp pr30 ls_flex_center"
                            @click.stop="viewMaterials(item)"
                        >
                            <i class="iconfont icon-tiezi mr6" />
                            <span class="fz14">用料</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
]);
// import * as echarts from 'echarts';

export default {
    data() {
        return {
            menu: [
                {
                    name: "选模板创建插花",
                    route: "/#/templateFlower",
                },
                {
                    name: "插花练习",
                    route:
                        this.$editAddr +
                        `?authToken=${localStorage.getItem("authToken")}`,
                    isOut: true,
                },
                {
                    name: "插花比赛",
                    route: "/#/race",
                },
                {
                    name: "教学课件",
                    route: "/#/courseware",
                },
                {
                    name: "插花作业",
                    route: "/#/homework",
                },
            ],

            query: {
                type: 2,
                pageIndex: 1,
                pageSize: 5,
            },
            total: 0,
            workList: [],

            currentSchool: {},
        };
    },
    mounted() {
        this.getWorkList();

        // formal
        this.currentSchool = JSON.parse(localStorage.getItem("currentSchool"));
    },
    methods: {
        nextPage() {
            if (this.query.pageIndex < this.total / 5) {
                ++this.query.pageIndex;
            } else {
                this.query.pageIndex = 1;
            }

            this.getWorkList();
        },
        getWorkList() {
            this.$ajax
                .post("/product/expand/queryList", this.query)
                .then(res => {
                    this.workList = res.list || [];
                    this.total = res.total;
                });
        },
        toEditDetail(item) {
            window.open(
                this.$editAddr +
                    "?mode=examine&productExpandId=" +
                    item.id +
                    `&authToken=${localStorage.getItem("authToken")}`
            );

            // 点赞
            this.$ajax
                .post("/product/expand/like", {
                    productExpandId: item.id,
                    likeType: 1,
                })
                .then(() => {
                    setTimeout(this.getWorkList, 1000);
                });
        },
        // 分享
        share(item) {
            this.$ajax
                .get("/product/createShareCode", {
                    params: { id: item.productId },
                })
                .then(res => {
                    this.$confirm(
                        <div>
                            <img
                                class="w300 h300"
                                src={Vue.filter("imgFormat")(res.data)}
                            />
                            <div class="mt25 fz18">
                                微信扫一扫，三维作品发送手机
                            </div>
                        </div>,
                        "",
                        {
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerouslyUseHTMLString: true,
                            center: true,
                        }
                    );
                });
        },
        // 查看用料
        viewMaterials(item) {
            let dom = "";
            if (!item.useMaterials) {
                this.$message.warning("暂无用料信息");
                return;
            }

            item.useMaterials.map(item => {
                dom += `<div class="ls_flex ls_aic">
                    <span class="lh26 fz14 cf21 mr10">⬤</span>
                    <span class="lh26 fz14">
                        ${item.materialName} x${item.count}
                    </span>
                </div>`;
            });

            this.$confirm(`<div>${dom}</div>`, "用料详情", {
                showCancelButton: false,
                showConfirmButton: false,
                dangerouslyUseHTMLString: true,
                width: "400px",
            });
        },
        openUrl(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    padding: 20px 20px 0 0;

    a {
        flex: 1;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;
        height: 70px;
        line-height: 70px;
        color: #fff;
        background: #31a458;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

        &:hover {
            color: #fff;
            background: #31a457c2;
        }

        + a {
            margin-left: 35px;
        }
    }
}

.chart_list {
    margin-top: 33px;
    width: 1114px;
    margin: 33px auto 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div {
        width: 500px;
        height: 300px;
    }
}

.tab_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;

    .tab_item {
        box-sizing: border-box;
        width: 49%;
        height: 300px;
        border: 1px solid #cccccc;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 20px 30px;
        background-color: #fff;
        cursor: pointer;
    }

    .list-box {
        height: 100%;
        overflow: scroll;
        /* firefox */
        scrollbar-width: none;
        /* IE 10+ */
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            line-height: 50px;
            height: 50px;
            border-bottom: 1px solid #afafaf;
        }
    }

    .no-data {
        text-align: center;
        line-height: 200px;
    }
}

.img_list {
    margin-top: 33px;
    overflow: hidden;
    width: 1114px;
    margin: 33px auto 0 auto;

    > img {
        float: left;
        width: 540px;
        margin-bottom: 33px;

        &:nth-child(2n + 1) {
            margin-right: 33px;
        }
    }
}
</style>
